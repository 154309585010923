export const ExperimentEnum = Object.freeze({

  A1: [   // экран целей
    'A',  // нынешний
    'B',  // 1.4.3
    // 'C',  // 6 вариантов с текстом А
    // 'D',  // 6 вариантов с текстом B
  ],

  A2: [   // имейл
    'A',  // с котиком
    'B',  // без котика
  ],

  A3: [   // TOP APP
    'A',  // с баннером
    'B',  // без баннера
  ],

  A4: [   // paywall кнопка + текст + таймер
    'A',  // с
    'B',  // без
  ],

  A5: {
    'A': 50,  // rive и старые картинки
    'B': 50,  // новые картинки
  },

  A6: [   // utm тексты на главной
    'A',  // поменяли
    'B',  // не меняли
  ],

  A7: [
    'A',  // текущая версия
    'B',  // меняем картинки на первом экране возраста, убираем котика
  ],

  A8: [
    'A',  // как есть
    'B',  // как есть, без прогреса
    'C',  // как есть + ачивка
    'D',  // как есть + тексты
    'E',  // все сразу
  ],

  A9: [
    'A',  // текущая версия
    'B',  // новый график
  ],

  A10: [
    'A',  // текущая версия
    'B',  // Science экран после цели 2 + добавить кастомизацию под цель (ток маскулинити)
  ],

  A11: [
    'A',  // текущая версия
    'B',  // новый экран Age
  ],

  A12: [
    'A',  // текущая версия
    'B',  // обновленный прелиминари процессинг
  ],

  A13: [
    'A',  // текущая версия
    'B',  // 3 новых экрана сразу после Preliminary processing общие для всех веток
  ],

  A14: [
    'A',  // текущая версия
    'B',  // +1 экран - Have you experienced any of these issues?
  ],

  A15: [
    'A',  // текущая версия
    'B',  // переносим экраны
  ],

  A16: [
    'A',  // текущая версия
    'B',  // новый блок на пейволе
  ],

  A17: [
    'A',  // как сейчас без перебивки
    'B',  // темная тема
    // 'C',  // светлая тема
  ],

  A18: [
    'A',  // none
    'B',  // EN_1
    'C',  // EN_2
  ],

  A19: [
    'A',  // none
    'B',  // Based on more than 2 million downloads on the App Store and Google Play
  ],

  A20: [
    'A',  // none
    'B',  // Now | In 3 months
  ],

  A21: [
    'A',  // none
    'B',  // EN_1
    'C',  // EN_2
  ],

  A22: [
    'A',  // none
    'B',  // Based on more than 2 million downloads on the App Store and Google Play
  ],

  A23: [
    'A',  // none
    'B',  // Now | In 3 months
  ],

  A24: {
    'A': 50,  // none
    'B': 50,  // Summarize
  },

  A25: [
    'A',  // none
    'B',  // Online since 2021
  ],

  A26: [
    'A',  // none
    'B',  // cookies
  ],

  A27: [
    'A',  // This offer ends in
    'B',  // Once you close offer, it’s gone
  ],

  A28: [
    'A',  // book
    'B',  // book2
  ],

  A29: [
    'A',  // none
    'B',  // new images celebrity
  ],

  A30: [
    'A',  // none
    'B',  // new timer
  ],

  A31: [
    'A',  // none
    'B',  // new coach ava
  ],

  A32: [
    'A',  // none
    'B',  // without promo
  ],
})
